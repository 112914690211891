import React from 'react';
import NavigationBar from './NavigationBar';
import PropTypes from 'prop-types';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const SupportGroupTemplate = ({ supportGroupData }) => {
  const {
    name,
    description,
    schedule,
    address: { street, city, state, zip, place_id }, // updated 'zipcode' to 'zip'
    specializations,
    lower_cost,
    upper_cost, // added upper cost
    phone,
    photo_url,
    related_pharmacies,
    related_psychiatrists
  } = supportGroupData;

  const googleMapsEmbedUrl = `https://www.google.com/maps/embed/v1/place?key=${process.env.REACT_APP_GOOGLE_API_KEY}&q=place_id:${place_id}`;

  // Helper function to determine the cost range
  const getCostSymbol = (lower_cost, upper_cost) => {
    const cost = (lower_cost + upper_cost) / 2;
    if (cost <= 50) return '$';
    if (cost <= 100) return '$$';
    return '$$$';
  };

  return (
    <>
      <NavigationBar activePath="/support-groups" />
      <Container className="mt-4">
        <Row>
          {/* Left: Support Group Image, Name, and Description */}
          <Col md={6}>
            <Card>
              <Card.Img variant="top" src={photo_url} alt={name} />
              <Card.Body>
                <Card.Title>{name}</Card.Title>
                <Card.Text>
                  <h4>Description</h4>
                  {description}
                </Card.Text>
                {/* Embed Google Map */}
                <div className="mt-4">
                  <h4>Location:</h4>
                  <p>{street}, {city}, {state}, {zip}</p>
                  <div className="embed-responsive embed-responsive-16by9" style={{ overflow: 'hidden' }}>
                    <iframe 
                      width="100%" 
                      height="300" 
                      frameBorder="0" 
                      style={{ border: 0 }} 
                      src={googleMapsEmbedUrl} 
                      allowFullScreen 
                      aria-hidden="false" 
                      tabIndex="0" 
                      title="Google Maps Embed" >
                      </iframe>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>

          {/* Right: Support Group Details */}
          <Col md={6}>
            <Card className="mb-4">
              <Card.Body>
                <h4>Contact Info:</h4>
                <Card.Text>{phone}</Card.Text>

                <h4>Meeting Time:</h4>
                <Card.Text>{schedule}</Card.Text>

                <h4>Focus Group:</h4>
                <Card.Text>{specializations.join(', ')}</Card.Text>

                <h4>Cost:</h4>
                <Card.Text>
                  <span style={{ fontSize: '1.5rem', marginRight: '0.5rem' }}>
                    {getCostSymbol(lower_cost, upper_cost)}
                  </span>
                  {lower_cost == upper_cost ? "$" + lower_cost : "$" + lower_cost + "-" + "$" + upper_cost}
                </Card.Text>

                <div className="mt-4">
                  <h4>Related Connections:</h4>
                  {related_pharmacies && related_pharmacies.length > 0 && (
                    <Button href={`/pharmacies/${related_pharmacies[0]}`} variant="secondary" className="me-2">
                      Related Pharmacy
                    </Button>
                  )}
                  {related_psychiatrists && related_psychiatrists.length > 0 && (
                    <Button href={`/psychiatrists/${related_psychiatrists[0]}`} variant="secondary">
                      Related Psychiatrist
                    </Button>
                  )}
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

SupportGroupTemplate.propTypes = {
  supportGroupData: PropTypes.shape({
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    schedule: PropTypes.string.isRequired,
    address: PropTypes.shape({
      street: PropTypes.string.isRequired,
      city: PropTypes.string.isRequired,
      state: PropTypes.string.isRequired,
      zip: PropTypes.string.isRequired,
      place_id: PropTypes.string.isRequired,
    }).isRequired,
    specializations: PropTypes.arrayOf(PropTypes.string).isRequired,
    lower_cost: PropTypes.number.isRequired,
    upper_cost: PropTypes.number.isRequired,
    phone: PropTypes.string.isRequired,
    photo_url: PropTypes.string.isRequired,
    related_pharmacies: PropTypes.array,
    related_psychiatrists: PropTypes.array,
  }).isRequired,
};

export default SupportGroupTemplate;
