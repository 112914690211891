import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery'; 
window.$ = $;
window.jQuery = $; // Make jQuery globally accessible
import NavigationBar from './NavigationBar';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import { FaStar, FaStarHalfAlt, FaRegStar } from 'react-icons/fa'; // For star icons

const PharmacyTemplate = ({ pharmacyData }) => {
  const {
    name,
    address: { street, city, state, zip, place_id }, // updated 'zipcode' to 'zip'
    phone,
    operating_hours_mon_fri: operatingHoursMonFri, // updated field name
    operating_hours_sat: operatingHoursSat, // updated field name
    operating_hours_sun: operatingHoursSun, // updated field name
    external_link: externalLink, // updated field name
    accepts_medicaid,
    photo_url,
    related_psychiatrists,
    related_support_groups
  } = pharmacyData;

  const [status, setStatus] = useState('Closed');
  const [rating, setRating] = useState(null); // Rating state
  const googleMapsEmbedUrl = `https://www.google.com/maps/embed/v1/place?key=${process.env.REACT_APP_GOOGLE_API_KEY}&q=place_id:${place_id}`;
  const googleMapsLink = `https://www.google.com/maps/place/?q=place_id:${place_id}`;

  // Helper function to check if the current time falls within operating hours
  const isOpenNow = (hours) => {
    try {
      if (!hours) return false;
      const now = new Date();
      const [start, end] = hours.split('-').map(time => time.trim());

      const startPastNoon = start.slice(-2) === "PM";
      const endPastNoon = end.slice(-2) === "PM";

      const [startBaseHour, startMinutes] = start.slice(0, -3).split(':').map(Number);
      const [endBaseHour, endMinutes] = end.slice(0, -3).split(':').map(Number);

      const startHour = startPastNoon ? (startBaseHour % 12) + 12 : startBaseHour;
      const endHour = endPastNoon ? (endBaseHour % 12) + 12 : endBaseHour;

      const openingTime = new Date();
      openingTime.setHours(startHour, startMinutes, 0, 0);

      const closingTime = new Date();
      closingTime.setHours(endHour, endMinutes, 0, 0);

      if (now >= openingTime && now <= closingTime) {
        const oneHourBeforeClose = new Date(closingTime.getTime() - 60 * 60 * 1000);
        return now >= oneHourBeforeClose ? 'Closing Soon' : 'Open';
      }

      return 'Closed';
    } catch (error) {
      return 'Closed';
    }
  };

  const checkOperatingHours = () => {
    const now = new Date();
    const dayOfWeek = now.getDay();

    let hours;
    if (dayOfWeek >= 1 && dayOfWeek <= 5) {
      hours = operatingHoursMonFri;
    } else if (dayOfWeek === 6) {
      hours = operatingHoursSat;
    } else if (dayOfWeek === 0) {
      hours = operatingHoursSun;
    }

    const currentStatus = isOpenNow(hours);
    setStatus(currentStatus);
  };

  useEffect(() => {
    checkOperatingHours();

    const loadGooglePlacesScript = () => {
      const script2 = document.createElement('script');
      script2.src = '/js/google-places.js'; 
      script2.async = true;
      document.head.appendChild(script2);

      const script3 = document.createElement('script');
      script3.src = `https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=places&key=${process.env.REACT_APP_GOOGLE_API_KEY}`;
      script3.async = true;
      document.head.appendChild(script3);
    };

    const loadGoogleReviews = () => {
      if ($) {
        $("#google-reviews").googlePlaces({
          placeId: place_id,
          render: ['reviews'],
          min_rating: 0,
          max_rows: 5
        });
      }
    };

    const fetchRatingFromGooglePlaces = () => {
      const service = new window.google.maps.places.PlacesService(document.createElement('div'));
      const request = {
        placeId: place_id,
        fields: ['rating'],
      };

      service.getDetails(request, (place, status) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK) {
          setRating(place.rating);
        }
      });
    };

    loadGooglePlacesScript();
    setTimeout(loadGoogleReviews, 1000);
    setTimeout(fetchRatingFromGooglePlaces, 1000); // Fetch rating after loading Google Places
  }, [place_id, operatingHoursMonFri, operatingHoursSat, operatingHoursSun]);

  // Helper function to render stars for the rating
  const renderStars = (rating) => {
    const fullStars = Math.floor(rating);
    const hasHalfStar = rating % 1 !== 0;
    const totalStars = 5;

    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {[...Array(fullStars)].map((_, index) => (
          <FaStar key={index} style={{ color: 'gold' }} />
        ))}
        {hasHalfStar && <FaStarHalfAlt style={{ color: 'gold' }} />}
        {[...Array(totalStars - fullStars - (hasHalfStar ? 1 : 0))].map((_, index) => (
          <FaRegStar key={index} style={{ color: 'gold' }} />
        ))}
      </div>
    );
  };

  return (
    <>
      <NavigationBar activePath="/pharmacies" />
      <Container className="mt-4">
        <Row>
          <Col md={6}>
            <Card>
              <Card.Img variant="top" src={photo_url} alt={name} />
              <Card.Body>
                <Card.Title>{name}</Card.Title>
                <div className="mt-4">
                  <h4>Location:</h4>
                  <p>{street}, {city}, {state}, {zip}</p>
                  <div className="embed-responsive embed-responsive-16by9" style={{ overflow: 'hidden' }}>
                    <iframe 
                      width="100%" 
                      height="300" 
                      frameBorder="0" 
                      style={{ border: 0 }} 
                      src={googleMapsEmbedUrl} 
                      allowFullScreen 
                      aria-hidden="false" 
                      tabIndex="0" 
                      title="Google Maps Embed" >
                      </iframe>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>

          <Col md={6}>
            <Card className="mb-4">
              <Card.Body>
                <h4>Rating:</h4>
                {rating ? renderStars(rating) : <p>Loading rating...</p>}
                <Card.Text><strong>Contact Info:</strong> {phone}</Card.Text>
                <Card.Text><strong>Accepts Medicaid:</strong> {accepts_medicaid ? "Yes" : "No"}</Card.Text>

                <h4>Operating Hours:</h4>
                <Card.Text>Mon-Fri: {operatingHoursMonFri || 'N/A'}</Card.Text>
                <Card.Text>Sat: {operatingHoursSat || 'N/A'}</Card.Text>
                <Card.Text>Sun: {operatingHoursSun || 'N/A'}</Card.Text>
                <Card.Text><strong>Status:</strong> {status}</Card.Text> {/* Display open/close status */}

                <div className="mt-4">
                  <Button href={googleMapsLink} target="_blank" rel="noopener noreferrer" variant="primary">
                    Visit Pharmacy Website
                  </Button>
                </div>

                <div className="mt-4">
                  <h4>Related Connections:</h4>
                  {related_psychiatrists && related_psychiatrists.length > 0 && (
                    <Button href={`/psychiatrists/${related_psychiatrists[0]}`} variant="secondary" className="me-2">
                      Related Psychiatrist
                    </Button>
                  )}
                  {related_support_groups && related_support_groups.length > 0 && (
                    <Button href={`/support-groups/${related_support_groups[0]}`} variant="secondary">
                      Related Support Group
                    </Button>
                  )}
                </div>
                
                {/* Scrollable reviews section */}
                <div style={{ marginTop: '20px', maxHeight: '300px', overflowY: 'scroll', border: '1px solid black', padding: '10px' }}>
                  <h4>Google Reviews:</h4>
                  <div id="google-reviews"></div>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

PharmacyTemplate.propTypes = {
  pharmacyData: PropTypes.shape({
    name: PropTypes.string.isRequired,
    address: PropTypes.shape({
      street: PropTypes.string.isRequired,
      city: PropTypes.string.isRequired,
      state: PropTypes.string.isRequired,
      zip: PropTypes.string.isRequired, // updated from 'zipcode'
      place_id: PropTypes.string.isRequired,
    }).isRequired,
    phone: PropTypes.string.isRequired,
    operating_hours_mon_fri: PropTypes.string, // updated field name
    operating_hours_sat: PropTypes.string, // updated field name
    operating_hours_sun: PropTypes.string, // updated field name
    external_link: PropTypes.string.isRequired, // updated field name
    rating: PropTypes.number.isRequired,
    accepts_medicaid: PropTypes.bool.isRequired,
    photo_url: PropTypes.string.isRequired,
    related_psychiatrists: PropTypes.array,
    related_support_groups: PropTypes.array
  }).isRequired,
};

export default PharmacyTemplate;
