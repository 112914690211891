import React from 'react';
import { Navbar, Nav } from 'react-bootstrap';

function NavigationBar({ activePath }) {
    const getNavLinkClass = (path) => {
        return activePath === path ? 'active' : '';
    };

    const instancesPerPage = 9;  // You can set a default or pass this as a prop
    const currentPage = 1;       // Default starting page

    return (
    <>
        <Navbar bg="dark" variant="dark" expand="lg">
            <Navbar.Brand href="/" style={{ paddingLeft: '20px' }}>TX ADHD Hub</Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="ml-auto">
                    <Nav.Link href="/" className={getNavLinkClass('/')}>Home</Nav.Link>
                    <Nav.Link href="/about" className={getNavLinkClass('/about')}>About</Nav.Link>
                    <Nav.Link href={`/support-groups?page=${currentPage}&limit=${instancesPerPage}`} className={getNavLinkClass('/support-groups')}>Support Groups</Nav.Link>
                    <Nav.Link href={`/psychiatrists?page=${currentPage}&limit=${instancesPerPage}`} className={getNavLinkClass('/psychiatrists')}>Licensed Psychiatrists</Nav.Link>
                    <Nav.Link href={`/pharmacies?page=${currentPage}&limit=${instancesPerPage}`} className={getNavLinkClass('/pharmacies')}>Pharmacies</Nav.Link>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    </>
    );
}

export default NavigationBar;
