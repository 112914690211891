import { Link } from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

// Helper function to format the key
const formatKey = (key) => {
  return key
    .split('_') // Split the key by underscore
    .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize each word
    .join(' '); // Join the words with a space
};

// Helper function to highlight search term
const highlightText = (text, searchTerm) => {
  if (!searchTerm || typeof text !== 'string') return text; // Ensure text is a string

  // Create a regular expression to find the search term, case insensitive
  const regex = new RegExp(`(${searchTerm})`, 'gi');

  // Split the text by the search term and wrap matches with a <span>
  return text.split(regex).map((part, index) =>
    part.toLowerCase() === searchTerm.toLowerCase() ? (
      <span key={index} style={{ backgroundColor: 'yellow' }}>{part}</span>
    ) : (
      part
    )
  );
};

const InstanceCard = ({ instance, modelType, index, searchTerm }) => {
  let modelKey;

  // Updated keys to reflect the API response structure
  switch (modelType) {
    case 'pharmacies':
      modelKey = ["rating", "twenty_four_hour_service", "address", "phone", "accepts_medicaid"];
      break;
    case 'psychiatrists':
      modelKey = ["specializations", "address", "cost", "phone", "accepts_insurance"];
      break;
    case 'support-groups':
      modelKey = ["schedule", "address", "specializations", "lower_cost", "therapy_types"];
      break;
    default:
      modelKey = [];
      break;
  }

  return (
    <Link to={`/${modelType}/${instance.id}`} style={{ textDecoration: 'none' }}>
      <Card
        style={{ width: '100%', minHeight: '100%', transition: 'box-shadow 0.3s, transform 0.3s' }}
        className="hover-card"
      >
        <Card.Img
          variant="top"
          src={instance['photo_url'] || instance['imageUrl']}
          alt={instance["name"]}
          style={{ height: '300px', objectFit: 'cover' }}
        />
        <Card.Body className="d-flex flex-column">
          <Card.Title>{highlightText(instance["name"], searchTerm)}</Card.Title>
          {modelKey.map((key, idx) => {
            if (key === "address") {
              const { street, city, state, zip } = instance.address || {};
              const address = street ? `${street}, ${city}, ${state}, ${zip}` : 'N/A';
              return (
                <Card.Text key={idx}>
                  <strong>Address:</strong> {highlightText(address, searchTerm)}
                </Card.Text>
              );
            }

            if (typeof key === "boolean") {
              return (
                <Card.Text key={idx}>
                  <strong>{formatKey(key)}:</strong> {instance[key] ? "Yes" : "No"}
                </Card.Text>
              );
            }

            // If instance[key] is an array, join the elements with a comma
            if (Array.isArray(instance[key])) {
              const text = instance[key].length > 0 ? instance[key].join(', ') : 'N/A';
              return (
                <Card.Text key={idx}>
                  <strong>{formatKey(key)}:</strong> {highlightText(text, searchTerm)}
                </Card.Text>
              );
            }

            const text = instance[key] || 'N/A';
            return (
              <Card.Text key={idx}>
                <strong>{formatKey(key)}:</strong> {highlightText(text, searchTerm)}
              </Card.Text>
            );
          })}
          <div className="mt-auto"></div>
        </Card.Body>
      </Card>
      <style jsx>{`
        .hover-card {
          border: 1px solid #ddd;
          border-radius: 4px;
          overflow: hidden;
        }
        .hover-card:hover {
          box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
          transform: translateY(-5px);
        }
      `}</style>
    </Link>
  );
};

export default InstanceCard;
