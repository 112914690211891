import React from 'react';
import { Container, Row, Col, Button, Image } from 'react-bootstrap';
import NavigationBar from './NavigationBar';
import { Link } from 'react-router-dom';
import homeBackground from '../images/Home_Background.jpg'; // Import the image

const Home = () => {
  return (
    <div>
      <NavigationBar activePath="/home" />

      <div style={{ backgroundColor: 'white', padding: '50px 0' }}>
        <Container className="text-center">
          <h1>Welcome to TX ADHD Hub</h1>
          <h4>Your resource for ADHD support and community engagement in Texas.</h4>
          <Button as={Link} to="/about" variant="primary" className="mt-3">
            About Us
          </Button>
        </Container>
      </div>

      <div className="text-center my-4">
        <Image
          src={homeBackground}
          alt="Home Background"
          style={{ width: '100%', height: 'auto', maxHeight: '500px', objectFit: 'cover' }}
          fluid
        />
      </div>

      <Container className="text-center mt-5">
        <h2>Explore Our Resources</h2>
        <Row className="mt-4">
          <Col md={4} className="d-flex justify-content-center">
            <Button
              as={Link}
              to="/support-groups"
              variant="primary"  
              style={{ width: '200px', height: '60px', fontSize: '18px', color: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            >
              Support Groups
            </Button>
          </Col>
          <Col md={4} className="d-flex justify-content-center">
            <Button
              as={Link}
              to="/psychiatrists"
              variant="primary"  // Solid blue background
              style={{ width: '200px', height: '60px', fontSize: '18px', color: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            >
              Psychiatrists
            </Button>
          </Col>
          <Col md={4} className="d-flex justify-content-center">
            <Button
              as={Link}
              to="/pharmacies"
              variant="primary"  // Solid blue background
              style={{ width: '200px', height: '60px', fontSize: '18px', color: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            >
              Pharmacies
            </Button>
          </Col>
        </Row>
      </Container>

      {/* Extra padding to allow for more scroll room */}
      <div style={{ paddingBottom: '100px' }}></div>
    </div>
  );
};

export default Home;
