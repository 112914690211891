/* eslint-disable react/prop-types */
import React from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import uniqueCities from '../filterData/psychiatrists_cities.json';
import uniqueZipCodes from '../filterData/psychiatrists_zip_codes.json';
import uniqueSpecializations from '../filterData/psychiatrists_specializations.json';


const PsychiatristFilter = ({
    city,
    setCity,
    zipCode,
    setZipCode,
    minCost,
    setMinCost,
    maxCost,
    setMaxCost,
    selectedSpecializations,
    setSelectedSpecializations,
    inPerson,
    setInPerson,
  }) => {
    return (
      <Form className="justify-content-center">
        <Row className="align-items-center justify-content-center">
          {/* City */}
          <Col xs="auto">
            <Form.Group controlId="psychiatristCity">
              <Form.Label>City</Form.Label>
              <Typeahead
                id="city-typeahead"
                onChange={(selected) => {
                  setCity(selected[0] || '');
                }}
                options={uniqueCities}
                placeholder="Choose a city..."
                selected={city ? [city] : []}
                highlightOnlyResult
                allowNew={false}
                filterBy={(option, props) => {
                  return option.toLowerCase().includes(props.text.toLowerCase());
                }}
              />
            </Form.Group>
          </Col>
  
          {/* Zip Code */}
          <Col xs="auto">
            <Form.Group controlId="psychiatristZipCode">
              <Form.Label>Zip Code</Form.Label>
              <Typeahead
                id="zip-code-typeahead"
                onChange={(selected) => {
                  setZipCode(selected[0] || '');
                }}
                options={uniqueZipCodes}
                placeholder="Choose a zip code..."
                selected={zipCode ? [zipCode] : []}
                highlightOnlyResult
                allowNew={false}
                filterBy={(option, props) => {
                  return option.toLowerCase().includes(props.text.toLowerCase());
                }}
              />
            </Form.Group>
          </Col>
  
          {/* Min Cost */}
          <Col xs="auto">
            <Form.Group controlId="psychiatristMinCost">
              <Form.Label>Min Cost</Form.Label>
              <Form.Control
                type="number"
                value={minCost || ''}
                onChange={(e) => setMinCost(e.target.value)}
                placeholder="Min"
                min="0"
              />
            </Form.Group>
          </Col>
  
          {/* Max Cost */}
          <Col xs="auto">
            <Form.Group controlId="psychiatristMaxCost">
              <Form.Label>Max Cost</Form.Label>
              <Form.Control
                type="number"
                value={maxCost || ''}
                onChange={(e) => setMaxCost(e.target.value)}
                placeholder="Max"
                min="0"
              />
            </Form.Group>
          </Col>
  
          {/* Specializations */}
          <Col xs="auto">
            <Form.Group controlId="psychiatristSpecializations">
              <Form.Label>Specializations</Form.Label>
              <Typeahead
                id="specializations-typeahead"
                onChange={(selected) => {
                  setSelectedSpecializations(selected);
                }}
                options={uniqueSpecializations}
                placeholder="Select specializations..."
                selected={selectedSpecializations}
                highlightOnlyResult
                multiple
                allowNew={false}
                filterBy={(option, props) => {
                  return option.toLowerCase().includes(props.text.toLowerCase());
                }}
              />
            </Form.Group>
          </Col>
  
          {/* In-Person */}
          <Col xs="auto">
            <Form.Group controlId="psychiatristInPerson">
              <Form.Label>In-Person</Form.Label>
              <Form.Control
                as="select"
                value={inPerson}
                onChange={(e) => setInPerson(e.target.value)}
              >
                <option value="">Any</option>
                <option value="true">Yes</option>
                <option value="false">No</option>
              </Form.Control>
            </Form.Group>
          </Col>
        </Row>
      </Form>
    );
  };
  
  export default PsychiatristFilter;
  