import React from 'react';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';

import About from './components/About';
import Home from './components/Home';
import GeneralInstance from './components/GeneralInstance'
import ModelList from './components/ModelList';

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path = "/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path = "/:model" element={<ModelList />} />
        <Route path="/:model/:id" element={<GeneralInstance />} />
      </Routes>
    </Router >);
};

export default App;
