import React from 'react';
import NavigationBar from './NavigationBar';
import PropTypes from 'prop-types';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const PsychiatristTemplate = ({ psychiatristData }) => {
  const {
    name,
    address: { street, city, state, zip, place_id }, // updated 'zipcode' to 'zip'
    specializations,
    cost,
    phone,
    accepts_insurance,
    description,
    photo_url,
    related_pharmacies,
    related_support_groups,
    online, // added field for online consultations
    in_person, // added field for in-person consultations
    waitlist, // added field for waitlist status
    unavailable // added field for availability status
  } = psychiatristData;

  const googleMapsEmbedUrl = `https://www.google.com/maps/embed/v1/place?key=${process.env.REACT_APP_GOOGLE_API_KEY}&q=place_id:${place_id}`;

  return (
    <>
      <NavigationBar activePath="/psychiatrists" />
      <Container className="mt-4">
        <Row>
          {/* Left: Psychiatrist Image, Name, and Description */}
          <Col md={6}>
            <Card>
              <Card.Img variant="top" src={photo_url} alt={name} />
              <Card.Body>
                <Card.Title>{name}</Card.Title>
                <div className="mt-4">
                  <h4>Description</h4>
                  <Card.Text>{description}</Card.Text>
                </div>
                {/* Embed Google Map */}
                <div className="mt-4">
                  <h4>Location:</h4>
                  <p>{street}, {city}, {state}, {zip}</p>
                  <div className="embed-responsive embed-responsive-16by9" style={{ overflow: 'hidden' }}>
                  <iframe 
                      width="100%" 
                      height="300" 
                      frameBorder="0" 
                      style={{ border: 0 }} 
                      src={googleMapsEmbedUrl} 
                      allowFullScreen 
                      aria-hidden="false" 
                      tabIndex="0" 
                      title="Google Maps Embed" >
                      </iframe>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>

          {/* Right: Psychiatrist Details */}
          <Col md={6}>
            <Card className="mb-4">
              <Card.Body>
                <h4>Contact Info:</h4>
                <Card.Text>{phone}</Card.Text>

                <h4>Specializations:</h4>
                <Card.Text>{specializations.join(', ')}</Card.Text>

                <h4>Cost:</h4>
                <Card.Text>${cost}</Card.Text>

                <h4>Accepts Insurance:</h4>
                <Card.Text>{accepts_insurance ? 'Yes' : 'No'}</Card.Text>

                <h4>Availability:</h4>
                <Card.Text>
                  {online && 'Online Consultations Available'}<br />
                  {in_person && 'In-Person Consultations Available'}<br />
                  {waitlist ? 'Waitlist Active' : 'No Waitlist'}<br />
                  {unavailable && 'Currently Unavailable'}
                </Card.Text>

                <div className="mt-4">
                  {/* Placeholder for external link */}
                  <Button href="#" target="_blank" rel="noopener noreferrer" variant="primary">
                    Visit Psychiatrist Website
                  </Button>
                </div>

                {/* Related connections */}
                <div className="mt-4">
                  <h4>Related Connections:</h4>
                  {related_pharmacies && related_pharmacies.length > 0 && (
                    <Button href={`/pharmacies/${related_pharmacies[0]}`} variant="secondary" className="me-2">
                      Related Pharmacy
                    </Button>
                  )}
                  {related_support_groups && related_support_groups.length > 0 && (
                    <Button href={`/support-groups/${related_support_groups[0]}`} variant="secondary">
                      Related Support Group
                    </Button>
                  )}
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

PsychiatristTemplate.propTypes = {
  psychiatristData: PropTypes.shape({
    name: PropTypes.string.isRequired,
    address: PropTypes.shape({
      street: PropTypes.string.isRequired,
      city: PropTypes.string.isRequired,
      state: PropTypes.string.isRequired,
      zip: PropTypes.string.isRequired, // changed from 'zipcode'
      place_id: PropTypes.string.isRequired,
    }).isRequired,
    specializations: PropTypes.arrayOf(PropTypes.string).isRequired,
    cost: PropTypes.number.isRequired,
    phone: PropTypes.string.isRequired,
    accepts_insurance: PropTypes.bool.isRequired,
    description: PropTypes.string.isRequired,
    photo_url: PropTypes.string.isRequired,
    related_pharmacies: PropTypes.array,
    related_support_groups: PropTypes.array,
    online: PropTypes.bool, // added prop validation for online consultations
    in_person: PropTypes.bool, // added prop validation for in-person consultations
    waitlist: PropTypes.bool, // added prop validation for waitlist status
    unavailable: PropTypes.bool // added prop validation for availability status
  }).isRequired,
};

export default PsychiatristTemplate;
